// start content

.modal-content{
    background: #ffffff;
    border-radius: 16px;
    border: 0;
}
#loginModal .modal-content .upela-form{
    background: transparent;
    padding: 0px 40px;
    margin-bottom: 0;
}
#loginModal .modal-dialog{
    max-width: 690px;
}
#loginModal .forget-password{
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
}
#loginModal .link-underlined{
    text-decoration: underline !important;
}
#loginModal .login-adds{
    font-size: 14px;
    line-height: 17px;
}
.btn.userLogin{
    margin-top: 27px;
    width: 278px;
    height: 56px;
    font-size: 18px;
    line-height: 15px;
    font-weight: bold;
    border-radius: 8px;
}
button.close.closeModal {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 25px;
    z-index: 9;
}